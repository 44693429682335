import { Box } from "@mui/material";
import { animated, useSpring } from "@react-spring/web"; // Import react-spring
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import embLogo from "../../../assets/logo/embLogo.png";
import { Menu } from "../../../constants/icons";
import WindowDimensions from "../../../hook/use-dimension";
import { setWidth } from "../../../redux/actions/users";
import SingleSideBar from "../../melecules/single-side-bar";
import "./side-bar.css";

function SideBar({ screens }) {
  const [hide, setHide] = useState(true);
  const [show, setShow] = useState(true);
  const { windowDimensions } = WindowDimensions();
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation(); 
  
     useEffect(() => {
      const currentPath = location.pathname;
      const activeIndex = screens.findIndex((screen) => screen.path === currentPath);
      if (activeIndex !== -1) {
          setActiveTab(activeIndex);
      }
  }, [location.pathname, screens]);

  useEffect(() => {
    dispatch(setWidth(hide ? 58 : windowDimensions.width / 5));
  }, [hide]);

  const hideDrawer = () => {
    setHide((prevHide) => !prevHide);
    if (hide) {
      setShow(false);
      setTimeout(() => setShow(true), 500); // Delay to match animation duration
    }
  };

  const openDrawer = (show, index) => {
    if (show) {
      setHide(false);
    } else {
      setHide(true);
    }
    if(index !== null){
      setActiveTab(index);
    }
  };

  // Animation properties for width and visibility
  const sidebarProps = useSpring({
    width: hide ? 58 : windowDimensions.width / 5,
    // opacity: hide ? 0 : 1,
    config: { duration: 300 },
  });

 
  return (
    <Box sx={{ display: "flex", overflow: "visible" }}>
      <animated.div
        style={{
          ...sidebarProps,
          height: windowDimensions.height,
          padding: 5,
          paddingLeft: 5,
          backgroundColor: "#19172C",
          borderRadius: 10,
          marginBottom: 10,
          overflow: "scroll",
          scrollbarWidth: "none" /* For Firefox */,
          "-ms-overflow-style": "none" /* For Internet Explorer and Edge */,
        }}
        id={"nav"}
      >
        <div
          style={{
            width: "100%",
            height: "10%",
            display: "flex",
            flexDirection: "row",
            borderBottomWidth: "1px", // Border bottom width
            borderBottomStyle: "solid", // Border bottom style
            // borderBottomColor: "#f2f2f2", // Border bottom color
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <img
            src={embLogo}
            alt="User Logo"
            style={{
              width: "100px",
              height: "30px",
              objectFit: "contain",
              display: hide ? "none" : "flex",
            }}
          />
          <Menu style={{ color: "white", fontSize: 18 }} onClick={hideDrawer} />
        </div>
      
          {screens &&
            show &&
            screens.map((item, index) => (
              <SingleSideBar
                item={item}
                key={item.id}
                openDrawer={openDrawer}
                activeTab={activeTab}
                index={index}
              />
            ))}
      </animated.div>
      {/* Optional extra space for animation */}
      {/* <div style={{width:30,display:hide?"flex":"none"}}/> */}
    </Box>
  );
}

export default SideBar;
