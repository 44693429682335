import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { IoIosRefreshCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import "./MainHeader.css"; // Import CSS file for styles

const MainHeader = ({
  title,
  searchBar,
  button,
  onClick,
  tab,
  onSearch,
  onReset,
}) => {
  const width = useSelector((state) => state.width.width);

  const [selected, setSelected] = useState(1);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const selectTap = (id) => {
    setSelected(id);
    onClick(id);
  };
  const [searchTerm, setSearchTerm] = useState("");
  // const searchvalue = searchTerm

  const handleSearch = () => {
    if (searchTerm.trim()) {
      onSearch(searchTerm, () => setSearchTerm(""));
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    if (onSearch) {
      onSearch("");
    }
  };

  return (
    <div
      className="main-header"
      style={{ width: window.innerWidth - width - 30 }}
    >
      <p className="header-title" style={{ width: window.innerWidth / 4 }}>
        {title}
      </p>

      <div className="tab">
        {tab &&
          tab.map((item) => (
            <div
              className="button"
              onClick={() => selectTap(item?.id)}
              key={item?.id}
              style={{
                backgroundColor: selected == item?.id ? "#19172C" : "#d3d3d3",
                width: window.innerWidth / 9,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                className="button-text"
                style={{ color: selected == item?.id ? "#fff" : "#000" }}
              >
                {item.title}
              </p>
              {/* <Add style={{ color: "white",fontSize:14 }} /> */}
            </div>
          ))}
      </div>
      {/* <input type="text" placeholder="Search..." className="search-box" onChange={onChange}/> */}
      {searchBar ? (
        <>
          <InputGroup style={{ width: "300px" }}>
            <Form.Control
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              as="button" // Force it to be a regular button
              style={{
                width: "auto",
                minWidth: "auto",
                padding: "0 8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // variant="outline-secondary"
              onClick={handleSearch}
            >
              <FaSearch size={14} />
            </Button>
          </InputGroup>
          <IoIosRefreshCircle
            style={{ color: "rgb(255, 87, 51)", cursor: "pointer" }}
            size={30}
            title="Reset Search"
            onClick={handleReset}
          />
        </>
      ) : (
        <div style={{ width: "300px" }} />
      )}
      {/* {button && (
        <div className="button" onClick={onClick}>
          <p className="button-text">Create</p>
          <Add style={{ color: "white",fontSize:14 }} />
        </div>
      )} */}

      <div className="user-profile">
        <img
          src="https://www.iconpacks.net/icons/2/free-user-icon-3296-thumb.png"
          alt="User Profile"
          style={{ height: 30, width: 30 }}
          onClick={() => signOut()}
        />
      </div>
    </div>
  );
};

export default MainHeader;
