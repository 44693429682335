import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  configApiClient,
  getTokenCredentials,
  getTokens,
} from "../services/aws/index";
import useStatusStore from "../services/zustand/status-popup";

const useAwsApiRequest = () => {
  const cred = useSelector((state) => state.cred.cred);
  const tokens = useSelector((state) => state.tokens.tokens);
  const setMessage = useStatusStore((state) => state?.setMessage);
  const userType = useSelector((state) => state?.userType);

  let tenantId = JSON.parse(localStorage.getItem("TenantID"));
  const [statusCode, setStatusCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  // Create a memoized function for the API request to avoid re-creating it on every render
  const apiRequest = useCallback(
    async (
      {
        pathParam,
        endpoint,
        method,
        body,
        defaultTen,
        headerCred,
        queryParams,
        userRole,
      },
      toast
    ) => {
      let config = {
        invokeUrl: process.env.REACT_APP_SERVICE_URL,
        region: tokens?.region,
        accessKey: cred?.accessKeyId,
        secretKey: cred?.secretAccessKey,
        sessionToken: cred?.sessionToken,
      };
      if (!cred || !tokens) {
        const { region } = await getTokens();
        const { accessKeyId, secretAccessKey, sessionToken } =
          await getTokenCredentials();
        config = {
          invokeUrl: process.env.REACT_APP_SERVICE_URL,
          region: region,
          accessKey: accessKeyId,
          secretKey: secretAccessKey,
          sessionToken: sessionToken,
        };
      }
      if (!tenantId) {
        tenantId = process.env.REACT_APP_USER_ROLE;
      }
      setError(null);
      setStatusCode(null);
      setLoading(true);

      if (!tenantId && !defaultTen) {
        setMessage(null, "Tenant Id Not Set", "error");
        return;
      }
      const apiClient = configApiClient(config);

      const additionalParams = {
        headers: headerCred
          ? headerCred
          : { "tenant-id": defaultTen || tenantId },
        queryParams: queryParams,
      };

      if (userRole) {
        additionalParams.userRole = userRole;
      }

      const updatedURL = endpoint.replace(/^http:\/\/localhost:\d+/, "");

      try {
        const response = await apiClient.invokeApi(
          pathParam,
          updatedURL,
          method,
          additionalParams,
          body
        );
        const status = response?.data?.status || response?.status;
        const message = response?.data?.message || "";
        const type =
          status === 200 || 201
            ? "success"
            : status === 400
            ? "warning"
            : "error";

        setData(response?.data);
        setLoading(false);
        setStatusCode(status);
        console.log("API response:", response);
        if (method === "POST" || toast) {
          setMessage(status, message, type);
        }
        return response?.data;
      } catch (err) {
        setLoading(false);
        const responseError = err?.response;
        if (responseError) {
          setError(responseError?.data.message || "An error occurred");
          setStatusCode(responseError?.data?.status);
          setMessage(
            responseError?.data?.status,
            responseError?.data.message || "An error occurred",
            "error"
          );
        } else {
          setStatusCode(500);
          setError("Network error");
          setMessage(500, "Network error", "error");
        }
      }

      setTimeout(() => {
        setError(null);
      }, 500);
    },
    [cred, tokens, tenantId, setMessage]
  );

  return { statusCode, loading, data, error, apiRequest };
};

export default useAwsApiRequest;
