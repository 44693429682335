import React, { useRef, useState } from "react";
import MainHeader from "../../components/melecules/main-header/main-header";
import BulkUploadVehicle from "../bulk-upload-vehicle/bulk-upload-vehicle";
import NewOnboardVehicle from "../new-onboard/new-onboard";
import TableList from "./table-list/table-list";
function OnboardVehicle({ editable }) {
  const Tabs = [
    { id: 1, title: "Onboarded Vehicle" },
    { id: 2, title: "Onboard" },
    { id: 4, title: "Bulk Upload" },
  ];
  const [activeTab, setActiveTab] = useState(1);
  const listRef = useRef();
  const [searchQuery, setSearchQuery] = useState(""); // State to hold search query
  const [selectedData, setSelectedData] = useState(null);

  const clickTab = (id, data) => {
    setActiveTab(id);
    setSelectedData(data);
  };

  const Tab = {
    1: (
      <TableList
        listRef={listRef}
        searchQuery={searchQuery}
        editData={clickTab}
      />
    ),
    2: <NewOnboardVehicle action={"create"} />,
    3: (
      <NewOnboardVehicle
        action={"edit"}
        goBack={clickTab}
        selectedData={selectedData}
      />
    ),
    4: <BulkUploadVehicle />,
  };

  const headerTitle = {
    1: "Onboarded Vehicle",
    2: "Onboard Vehicle",
    3: "Update Vehicle Model",
    4: "Bulk Upload",
  };

  const onChangeSearch = (e) => {
    // setSearchQuery(e.target.value);
  };

  const handleSearchVehicle = (query) => {
    setSearchQuery(query);
  };

  return (
    <div>
      <MainHeader
        title={headerTitle[activeTab]}
        searchBar={activeTab === 1}
        button={true}
        onClick={clickTab}
        tab={Tabs}
        // onChange={onChangeSearch}
        onSearch={handleSearchVehicle}
      />
      {Tab[activeTab]}
    </div>
  );
}

export default OnboardVehicle;
