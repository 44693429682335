import AWS from "aws-sdk";
import Papa from "papaparse";
import React, { useRef, useState } from "react";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import fileIcon from "../../assets/lottie/fileIcon.json";
import pdfFile from "../../assets/pdf/vehicles_data-10k.csv";
import FormContainer from "../../components/organizer/form-container/form-container";
import useAwsApiRequest from "../../hook/useAwsApiRequest";
import useStatusStore from "../../services/zustand/status-popup";
import { removeCsvExtension } from "../../utilities/helper";
import "./bulkUpload.css";
import LoadingPopup from "./loading-pipup/loading-popup";
import UploadHistory from "./upload-history/upload-history";
const BulkUploadVehicle = () => {
  let tenantId = JSON.parse(localStorage.getItem("TenantID"));
  const { statusCode, loading, data, error, apiRequest } = useAwsApiRequest();

  const fileSize = 5;
  const cred = useSelector((state) => state.cred.cred);
  const tokens = useSelector((state) => state.tokens.tokens);
  const users = useSelector((state) => state.users.users);
  const [file, setFile] = useState(null);
  const [success, setSuccess] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Validating");
  const [showPopup, setShowPopup] = useState(false);
  const setMessage = useStatusStore((state) => state?.setMessage);
  const bucketName = `s3-lambda-vehicle-bulk-upload`;

  const intervalId = useRef();
  const uploadHistoryRef = useRef();

  const s3 = new AWS.S3({
    region: tokens?.region,
    accessKeyId: cred?.accessKeyId,
    secretAccessKey: cred?.secretAccessKey,
    sessionToken: cred?.sessionToken,
  });

  const defaultOptions = {
    loop: !success,
    autoplay: true,
    animationData: fileIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const expectedHeaders = [
    "vin",
    "model_name",
    "fleet_name",
    "thing_name",
    "firmware_version",
    "version",
    "region",
    "onboarded_status",
  ];

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const validateHeaders = (headers) => {
    return expectedHeaders.every((header) => headers.includes(header));
  };

  const validateAndUpload = () => {
    if (!file) {
      return;
    }
    setShowPopup(true);
    if (file?.type !== "text/csv") {
      setMessage(null, "Wrong file format.", "error");
      setShowPopup(false);
      return;
    }

    if (file.size > fileSize * 1024 * 1024) {
      setMessage(null, "File size exceeds 5MB.", "error");
      setShowPopup(false);
      return;
    }

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const headers = results.meta.fields;
        if (validateHeaders(headers)) {
          // setMessage(null, "Successfully Validated", "success");
          uploadToS3();
        } else {
          setMessage(
            null,
            "CSV headers are invalid. Expected headers: " +
              expectedHeaders.join(", "),
            "error"
          );
          setShowPopup(false);
        }
      },
      error: (err) => {
        setMessage(null, `Error parsing CSV file: ${err?.message}`, "error");
        setShowPopup(false);
      },
    });
  };

  const uploadToS3 = async () => {
    const filename = `${removeCsvExtension(file?.name)}-${new Date()
      .toISOString()
      .replace(/:/g, "_")}.csv`;
    const path = `${
      tenantId || process.env.REACT_APP_USER_ROLE
    }$${users}$${filename}`;

    setStatusMessage("Uploading");
    const params = {
      Bucket: bucketName,
      Key: path,
      Body: file,
      ContentType: file?.type,
    };

    try {
      console.log("bucket uploading", params);

      const res = await s3.upload(params).promise();
      console.log("response from s3:", res);
      const s3URL = res.Location;
      console.log("s3URL", s3URL);

      if (s3URL) {
        setStatusMessage("Processing");
        uploadToS3url(s3URL);
      }
    } catch (err) {
      console.log("bucket", err);
      setMessage(null, `File Uploading Failed`, "error");
      setShowPopup(false);
      setStatusMessage("Uploading");
    }
  };
  //each 5 seconds check file is validated or not

  const uploadToS3url = async (s3Url) => {
    let data = {
      pathParam: {},
      endpoint: "http://localhost:8001/vehicleservice/vehicle/upload",
      method: "POST",
      body: { bucketname: bucketName, key: s3Url },
    };

    const response = await apiRequest(data);
    console.log("Response from upload api:", response);
    if (response || response?.status == 200) {
      setShowPopup(false);
      setStatusMessage("Uploading");
      setMessage(null, `File Successfully Validated: Check History`, "success");
      uploadHistoryRef.current?.loadData();
    }
  };

  const downloadDemoFile = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "Demo-vehicle-data.csv"; // Specify the filename for download
    link.click();
  };

  return (
    <div className="bulk-container">
      <FormContainer
        // minusWidth={350}
        uploadform={true}
        header={true}
        title={"Upload File"}
        IconButton={true}
        iconTitle={"Demo"}
        handleSubmitIconButton={downloadDemoFile}
      >
        <div className="file-upload">
          <Lottie options={defaultOptions} height={100} width={100} />
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="csv-file-input"
          />
          <div className="status-box">
            <ol>
              <li>File Format Should Be CSV</li>
              <li>File Size Should be Below {fileSize}mb</li>
            </ol>
          </div>
          <div className="status-bar">
            <div
              className="status-bar-box"
              style={{
                backgroundColor: file ? "#19172C" : "gray",
              }}
              onClick={validateAndUpload}
            >
              Upload
            </div>
          </div>
        </div>
      </FormContainer>
      <UploadHistory ref={uploadHistoryRef} />
      {showPopup && <LoadingPopup message={statusMessage} />}
    </div>
  );
};

export default BulkUploadVehicle;
